import { Controller } from "@hotwired/stimulus";
import {
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
} from "chart.js";

export default class extends Controller {
  static values = {
    labels: Array,
    dataset: Array,
    currency: Boolean,
  };

  async connect() {
    Chart.register(
      CategoryScale,
      Legend,
      LineController,
      LineElement,
      LinearScale,
      PointElement,
    );

    const data = this.getData();
    const options = this.getOptions();
    const context = this.element.getContext("2d");

    new Chart(context, { type: "line", data, options });
  }

  getData() {
    return {
      labels: this.labelsValue,
      datasets: this.datasetValue,
    };
  }

  getOptions() {
    return {
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            callback: (value) => (this.currencyValue ? `$${value}` : value),
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    };
  }
}
