import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    category: String,
    action: String,
  };

  onClick() {
    if (window.gtag !== undefined) {
      window.gtag("event", this.actionValue, {
        event_category: this.categoryValue,
      });
    }
  }
}
