import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["image", "card"];

  static values = {
    index: Number,
  };

  connect() {
    this.setSelection(2);

    const observer = new IntersectionObserver(this.onIntersection);

    observer.observe(this.element);
  }

  startCardCycling() {
    this.cyclingInterval = setInterval(
      this.incrementSelection.bind(this),
      3000,
    );
  }

  incrementSelection() {
    const newValue = this.indexValue + 1;

    if (newValue > this.imageTargets.length) {
      this.setSelection(1);
    } else {
      this.setSelection(newValue);
    }
  }

  onIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.startCardCycling();
        observer.unobserve(this.element);
      }
    });
  };

  change(event) {
    clearInterval(this.cyclingInterval);
    this.setSelection(event.currentTarget.dataset.cardCarouselImage);
  }

  setSelection(value) {
    this.indexValue = value;

    this.cardTargets.forEach((card) => {
      ["lg:border-red-500", "lg:-mr-2", "lg:ml-2"].forEach((klass) => {
        card.classList.toggle(klass, card.dataset.cardCarouselImage == value);
      });
    });

    this.imageTargets.forEach((image) => {
      image.classList.toggle(
        "hidden",
        image.dataset.cardCarouselImage != value,
      );
    });
  }
}
