import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";
import { toggle, leave } from "el-transition";

export default class extends Controller {
  static targets = ["menu"];

  connect() {
    useClickOutside(this, { element: this.menuTarget });
  }

  toggle(event) {
    event.preventDefault();

    toggle(this.menuTarget, "dropdown");
  }

  close(event) {
    leave(this.menuTarget, "dropdown");
  }

  clickOutside(event) {
    this.close();
  }
}
