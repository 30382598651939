import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "pane"];

  static values = {
    currentId: String,
  };

  click(event) {
    event.preventDefault();

    this.currentIdValue = event.target.closest('[data-tab-target="tab"]').id;

    this.render();
  }

  render() {
    this.tabTargets.forEach((element) => {
      element.classList.toggle("active", element.id == this.currentIdValue);
    });

    this.paneTargets.forEach((element) => {
      element.classList.toggle(
        "active",
        element.id == `${this.currentIdValue}-tab`,
      );
    });
  }
}
