import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["active"];

  OFFSET = 60;

  connect() {
    if (this.hasActiveTarget) {
      if (
        this.activeTarget.offsetLeft + this.activeTarget.offsetWidth >=
        this.activeTarget.parentElement.offsetWidth
      ) {
        this.activeTarget.parentElement.scrollTo({
          top: 0,
          left:
            this.activeTarget.offsetLeft +
            this.activeTarget.offsetWidth -
            this.activeTarget.parentElement.offsetWidth +
            this.OFFSET,
          behavior: "instant",
        });
      }
    }
  }
}
