import { Controller } from "@hotwired/stimulus";
import { toggle, leave } from "el-transition";

export default class extends Controller {
  static targets = ["video"];

  pause() {
    this.videoTarget.pause();
  }
}
