import { Controller } from "@hotwired/stimulus";
import { DirectUploadsController } from "@rails/activestorage/src/direct_uploads_controller";

const MAX_FILE_SIZE = 6000000;
const MAX_FILE_COUNT = 10;

export default class extends Controller {
  connect() {
    this.element.addEventListener("submit", this.didSubmitForm.bind(this));
    this.element.addEventListener(
      "ajax:before",
      this.didSubmitRemoteElement.bind(this),
    );
  }

  didSubmitForm(event) {
    this.handleValidations(event);
  }

  didSubmitRemoteElement(event) {
    if (event.target.tagName == "FORM") {
      this.handleValidations(event);
    }
  }

  handleValidations(event) {
    this.handleFileCountValidation(event);
    this.handleFileSizeValidation(event);
  }

  handleFileCountValidation(event) {
    const directUploads = new DirectUploadsController(this.element);

    if (directUploads.inputs.length) {
      const fileCount = directUploads.inputs[0].files.length;

      if (fileCount > MAX_FILE_COUNT) {
        alert(
          `Please limit your selection to ${MAX_FILE_COUNT} or less files per upload.`,
        );
        this.preventUpload(event);
      }
    }
  }

  handleFileSizeValidation(event) {
    const largeFiles = this.largeFiles;

    if (largeFiles.length) {
      alert(
        `The following file(s) are too large to be uploaded:\n\n${largeFiles.join(
          "\n",
        )}\n\nMaximum allowed file size is 6.0MB.`,
      );
      this.preventUpload(event);
    }
  }

  get largeFiles() {
    const directUploads = new DirectUploadsController(this.element);

    let largeFiles = [];

    Array.from(directUploads.inputs).forEach((input) => {
      Array.from(input.files).forEach((file) => {
        if (file.size >= MAX_FILE_SIZE) {
          largeFiles.push(
            `${file.name} (${(file.size / 1000000).toFixed(2)} MB)`,
          );
        }
      });
    });

    return largeFiles;
  }

  preventUpload(event) {
    event.stopPropagation();
    event.preventDefault();
  }
}
