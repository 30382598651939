import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect() {
    this.buttonTarget.disabled = true;
  }

  trigger(e) {
    this.buttonTarget.disabled = false;
  }
}
