import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link", "content"];

  static values = {
    show: Boolean,
  };

  connect() {
    if (this.showValue) {
      this.render();
    }
  }

  click(event) {
    event.preventDefault();

    if (event.target.disabled) {
      return;
    }

    this.render();
  }

  render() {
    this.linkTarget.classList.toggle("expandable__trigger--hide");
    this.contentTarget.classList.toggle("expandable__content--show");
  }
}
