import { Controller } from "@hotwired/stimulus";

// Usage:
// Values:
// active: determines the starting "toggle" value of content elements
// disabled: when true, will not toggle anything
// Classes:
// active: when "active" the currently assigned class. Will be the same for all "content" elements.
// extra functionality: if several elements required different toggled classes, add `data-toggle-active-class` to each "content" target element
// Actions
// Gotcha: use the :prevent suffix when using on links
// E.g. <a href="#" data-action="toggle#toggle:prevent">Toggle</a>

export default class extends Controller {
  static targets = ["content"];
  static classes = ["active", "target"];
  static values = {
    disabled: { type: Boolean, default: false },
    active: Boolean,
  };

  connect() {
    if (this.disabledValue) {
      return;
    }

    if (this.hasActiveValue && this.activeValue == true) {
      this.activeValue = !this.activeValue;
      this.toggle();
    }
  }

  toggle(event) {
    if (this.disabledValue) {
      return;
    }

    this.activeValue = !this.activeValue;

    if (this.hasTargetClass) {
      event.currentTarget.classList.toggle(this.targetClass);
    }

    this.contentTargets.forEach((element) => {
      const activeClasses = (
        element.dataset.toggleActiveClass || this.activeClass
      ).split(" ");

      activeClasses.forEach((activeClass) => {
        element.classList.toggle(activeClass);
      });
    });
  }
}
