import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["error"];

  onInput(event) {
    this.errorTarget.classList.toggle(
      "hidden",
      !this.mentionsWinz(event.target.value),
    );
  }

  mentionsWinz(string) {
    return string.toLowerCase().includes("winz");
  }
}
