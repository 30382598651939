import { Controller } from "@hotwired/stimulus";
import { useIntersection } from "stimulus-use";

export const MYRENT_BLUE = "#008cb8";

export default class extends Controller {
  static values = {
    token: String,
    address: String,
    suburb: String,
    latitude: Number,
    longitude: Number,
    circle: Boolean,
  };

  initialize() {
    mapkit.init({
      authorizationCallback: (done) => {
        done(this.tokenValue);
      },
    });
  }

  connect() {
    useIntersection(this);
  }

  disconnect() {
    mapkit.maps.map((map) => map.destroy());
  }

  appear(entry) {
    if (entry.isIntersecting && mapkit.maps.length === 0) {
      this.initializeMap();
    }
  }

  initializeMap() {
    const map = new mapkit.Map(this.element, this.getOptions());

    map.cameraDistance = 2000;
    map.cameraZoomRange = new mapkit.CameraZoomRange(300, 30000);
  }

  getOptions() {
    const region = new mapkit.CoordinateRegion(
      this.coordinate,
      new mapkit.CoordinateSpan(0.05, 0.11),
    );

    const annotations = this.circleValue ? [] : [this.getAnnotation()];

    const overlays = this.circleValue ? [this.getOverlay()] : [];

    return {
      center: this.coordinate,
      tintColor: MYRENT_BLUE,
      annotations: annotations,
      selectedAnnotation: annotations[0],
      overlays: overlays,
      region: region,
    };
  }

  getAnnotation() {
    const annotation = new mapkit.MarkerAnnotation(this.coordinate);
    annotation.color = MYRENT_BLUE;
    annotation.title = this.addressValue;
    annotation.subtitle = this.suburbValue;
    annotation.glyphText = "🏡";
    return annotation;
  }

  getOverlay() {
    const overlay = new mapkit.CircleOverlay(this.coordinate, 500);
    overlay.style = new mapkit.Style({
      lineWidth: 2,
      strokeColor: "#008cb8",
      fillColor: "#008cb8",
    });
    return overlay;
  }

  get coordinate() {
    return new mapkit.Coordinate(this.latitudeValue, this.longitudeValue);
  }
}
