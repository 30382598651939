import "lightgallery.js";
import "lg-video.js";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["gallery", "image"];

  connect() {
    lightGallery(this.galleryTarget, {
      speed: 200,
      download: false,
    });
  }

  disconnect() {
    this.gallery?.destroy();
  }

  openImages(event) {
    event.preventDefault();

    this.imageTargets[0]?.click();
  }

  openVideo(event) {
    event.preventDefault();

    this.imageTargets[this.imageTargets.length - 1]?.click();
  }

  get gallery() {
    return window.lgData[this.galleryTarget.getAttribute("lg-uid")];
  }
}
