import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "label"];

  change(event) {
    const { files } = event.target;

    let description = `${files.length} files selected`;

    if (files.length === 1) {
      description = files[0].name;
    }

    this.labelTarget.innerHTML = description;
  }

  reset() {
    if (this.hasInputTarget && this.inputTarget.files.length > 0) {
      this.inputTarget.value = "";
      this.labelTarget.innerHTML = "Choose file...";
    }
  }
}
