import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import { enter } from "el-transition";

export default class extends Controller {
  static targets = ["select", "allowLink", "restrictLink"];

  connect() {
    this.#setup();
    this.#initialiseAllowedDates();
  }

  allowAny(event) {
    event.preventDefault();
    this.#enableAllDates();
    enter(this.selectTarget);
  }

  restrict(event) {
    event.preventDefault();
    this.#enableRentDates();
    enter(this.selectTarget);
  }

  #setup() {
    this.datePicker = flatpickr(this.selectTarget, {
      dateFormat: "d/m/Y",
      allowInput: true,
      altInput: true,
      defaultDate: this.#initialDate(),
      altFormat: "D, j M Y",
    });
  }

  get #selectParams() {
    return JSON.parse(this.element.dataset.selectParams);
  }

  #initialDate() {
    return this.#selectParams.selected_date != null
      ? this.#selectParams.selected_date
      : this.#selectParams.rent_period_min;
  }

  #initialiseAllowedDates() {
    if (
      this.#selectParams.selected_date &&
      !this.#selectParams.allowed_dates.includes(
        this.#selectParams.selected_date,
      )
    ) {
      this.#enableAllDates();
      this.datePicker.set("defaultDate", this.#selectParams.selected_date);
    } else {
      this.#enableRentDates();
    }
  }

  #enableAllDates() {
    const minFreeEntryDate = this.#selectParams.free_select_min;
    if (minFreeEntryDate) {
      this.datePicker.set("enable", [
        (date) => date >= flatpickr.parseDate(minFreeEntryDate, "d/m/Y"),
      ]);
    } else {
      this.datePicker.set("enable", [() => true]);
    }
    this.allowLinkTarget.classList.add("hidden");
    this.restrictLinkTarget.classList.remove("hidden");
  }

  #enableRentDates() {
    this.datePicker.set("enable", this.#selectParams.allowed_dates);
    this.allowLinkTarget.classList.remove("hidden");
    this.restrictLinkTarget.classList.add("hidden");
  }
}
