// Use case:
// A "Quick select" dropdown to fill a date offset from another date field, ie, 12 months

import { Controller } from "@hotwired/stimulus";
import { enter } from "el-transition";

export default class extends Controller {
  static targets = ["anchorDate", "offsetDate"];

  onChange(event) {
    let offsetMonths = parseInt(event.target.value);
    event.target.value = "";

    if (!this.anchorDateTarget?.value) {
      return;
    }

    if (!offsetMonths) {
      return;
    }

    let anchorDate = new Date(this.anchorDateTarget.value);

    anchorDate.setMonth(anchorDate.getMonth() + offsetMonths);
    // Set hour so that DLS doesn't shift the date by 1 day when date (which has a timezone) is converted to ISO
    anchorDate.setHours(2);

    this.offsetDateTarget.value = new Date(anchorDate)
      .toISOString()
      .split("T")[0];

    enter(this.offsetDateTarget);
  }
}
