import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["backdrop", "content"];

  connect() {
    document.body.classList.add("overflow-hidden");

    Promise.all([enter(this.backdropTarget), enter(this.contentTarget)]);
  }

  disconnect() {
    document.body.classList.remove("overflow-hidden");
  }

  onClick(event) {
    if (event.target.closest(".flatpickr-calendar")) {
      return;
    }

    if (!this.contentTarget.contains(event.target)) {
      this.close();
    }
  }

  async close() {
    await Promise.all([leave(this.backdropTarget), leave(this.contentTarget)]);

    this.element.remove();
  }
}
