import { Controller } from "@hotwired/stimulus";
import { useIntersection } from "stimulus-use";

export default class extends Controller {
  static values = {
    id: String,
    target: String,
    offset: Number,
  };

  connect() {
    if (!document.querySelector(this.targetValue)) {
      return;
    }

    useIntersection(this);
  }

  scroll() {
    const targetElement = document.querySelector(this.targetValue);
    this.element.scrollTop = targetElement.offsetTop + this.offsetValue;
  }

  appear(entry) {
    if (entry.isIntersecting) {
      this.scroll();
    }
  }
}
