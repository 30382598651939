import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "togglePositive",
    "toggleNegative",
    "hiddenAmount",
    "displayAmount",
  ];

  static values = {
    positivePolarity: Boolean,
    forcedPositive: Boolean,
  };

  connect() {
    this.setInitialPolarity();
    this.updateToggleButtonVisibilities();
  }

  setInitialPolarity() {
    if (this.hiddenAmountTarget.value) {
      this.positivePolarityValue = this.hiddenAmountTarget.value >= 0;
    } else {
      this.positivePolarityValue = false;
    }
  }

  toggle(event) {
    if (event) {
      event.preventDefault();
    }

    if (!this.forcedPositiveValue) {
      this.positivePolarityValue = !this.positivePolarityValue;
    }

    this.updateHiddenAmount();
    this.updateToggleButtonVisibilities();
  }

  updateHiddenAmount() {
    this.hiddenAmountTarget.value = this.displayAmountTarget.value;

    if (this.positivePolarityValue) {
      this.hiddenAmountTarget.value = Math.abs(this.displayAmountTarget.value);
    } else {
      this.hiddenAmountTarget.value =
        Math.abs(this.displayAmountTarget.value) * -1;
    }

    this.broadcastAmountChange();
  }

  updateToggleButtonVisibilities() {
    this.togglePositiveTarget.classList.toggle(
      "hidden",
      !this.positivePolarityValue,
    );
    this.toggleNegativeTarget.classList.toggle(
      "hidden",
      this.positivePolarityValue,
    );
  }

  enforcePositiveAmount({ detail: { rentSelected } }) {
    if (rentSelected) {
      this.forcedPositiveValue = true;
      this.positivePolarityValue = true;
      this.toggle();
    } else {
      this.forcedPositiveValue = false;
    }
  }

  broadcastAmountChange() {
    this.dispatch("change");
  }
}
