import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["switch", "indicator", "input"];
  static values = { active: Boolean };
  static classes = ["switchActive", "switchInactive"];

  connect() {
    this.updateSwitch();
    this.updateInput();
  }

  toggle() {
    this.activeValue = !this.activeValue;
    this.updateSwitch();
    this.updateInput();
  }

  updateSwitch() {
    const switchClassToAdd = this.activeValue
      ? this.switchActiveClass || "bg-blue-500"
      : this.switchInactiveClass || "bg-gray-200";
    const switchClassToRemove = this.activeValue
      ? this.switchInactiveClass || "bg-gray-200"
      : this.switchActiveClass || "bg-blue-500";
    const indicatorClassToAdd = this.activeValue
      ? "translate-x-5"
      : "translate-x-0";
    const indicatorClassToRemove = this.activeValue
      ? "translate-x-0"
      : "translate-x-5";

    this.toggleClasses(
      this.switchTarget,
      switchClassToAdd,
      switchClassToRemove,
    );
    this.toggleClasses(
      this.indicatorTarget,
      indicatorClassToAdd,
      indicatorClassToRemove,
    );
  }

  updateInput() {
    this.inputTarget.value = this.activeValue ? "1" : "0";
  }

  toggleClasses(element, classToAdd, classToRemove) {
    element.classList.add(classToAdd);
    element.classList.remove(classToRemove);
  }
}
