import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["open"];

  connect() {
    document.addEventListener("turbo:visit", this.onVisit);
    this.initialOffset = this.element.offsetTop;
  }

  disconnect() {
    document.removeEventListener("turbo:visit", this.onVisit);
  }

  onToggle(event) {
    event.preventDefault();

    this.element.classList.toggle(this.openClass);
  }

  onVisit = () => {
    this.element.classList.remove(this.openClass);
  };

  onScroll = () => {
    this.setStickyness();
  };

  setStickyness() {
    // Disabling stickiness as it breaks the "annotation" underlines
    // Assumes that the element following the header has no margin
    // if (
    //   window.scrollY > this.initialOffset &&
    //   !this.element.classList.contains("sticky-menu")
    // ) {
    //   this.element.classList.add("sticky-menu", "sticky-menu--block", "top-0");
    //   if (this.element.nextSibling) {
    //     this.element.nextSibling.style.marginTop = `${this.element.offsetHeight}px`;
    //   }
    // } else if (
    //   window.scrollY <= this.initialOffset &&
    //   this.element.classList.contains("sticky-menu")
    // ) {
    //   this.element.classList.remove(
    //     "sticky-menu",
    //     "sticky-menu--block",
    //     "top-0",
    //   );
    //   if (this.element.nextSibling) {
    //     this.element.nextSibling.style.marginTop = "0";
    //   }
    // }
  }
}
