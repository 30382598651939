import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";

export default class extends Controller {
  static values = {
    title: String,
  };

  connect() {
    this.tooltip = tippy(this.element, {
      placement: "top",
      allowHTML: true,
      content: this.titleValue,
      interactive: true,
    });
  }

  disconnect() {
    this.tooltip?.destroy();
  }
}
