import { Controller } from "@hotwired/stimulus";
import { toggle, leave } from "el-transition";

// In order to support more than one instance of a player on the page, instances of this controller are being stored
// on the class "instances" so that each are loaded. Otherwise, there is a race condition as to which player is started
// when the global `window.onYouTubeIframeAPIReady` callback is called.

export default class extends Controller {
  static targets = ["container", "backdrop", "modal", "video"];
  static values = { id: String };
  static instances = [];

  connect() {
    this.constructor.instances.push(this);
    this.loadYouTubeAPIIfNeeded();
  }

  loadYouTubeAPIIfNeeded() {
    if (window.YT) {
      this.createPlayer();
      return;
    }

    if (!window.onYouTubeIframeAPIReady) {
      window.onYouTubeIframeAPIReady = this.constructor.initializePlayers.bind(
        this.constructor,
      );
    }

    if (!this.isYouTubeScriptLoaded()) {
      this.loadYouTubeScript();
    }
  }

  static initializePlayers() {
    this.instances.forEach((instance) => instance.createPlayer());
  }

  isYouTubeScriptLoaded() {
    return !!document.querySelector(
      'script[src="https://www.youtube.com/iframe_api"]',
    );
  }

  loadYouTubeScript() {
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  disconnect() {
    const index = this.constructor.instances.indexOf(this);
    if (index > -1) {
      this.constructor.instances.splice(index, 1);
    }
    if (this.player) {
      this.player.destroy();
    }
  }

  createPlayer() {
    this.player = new YT.Player(this.videoTarget, {
      videoId: this.idValue,
      playerVars: {
        autoplay: 0,
        modestbranding: 1,
        rel: 0,
      },
    });
  }

  async click() {
    this.containerTarget.classList.toggle("hidden");
    await Promise.all([toggle(this.backdropTarget), toggle(this.modalTarget)]);

    if (this.player && this.player.playVideo) {
      this.player.playVideo();
    }
  }

  async close() {
    await Promise.all([leave(this.backdropTarget), leave(this.modalTarget)]);
    this.containerTarget.classList.add("hidden");

    if (this.player && this.player.pauseVideo) {
      this.player.pauseVideo();
    }
  }

  onClick(event) {
    if (
      event.target.contains(this.containerTarget) &&
      !event.target.contains(this.modalTarget)
    ) {
      this.close();
    }
  }
}
