import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="issues--message"
export default class extends Controller {
  static targets = ["content", "closeButton", "sendButton"];
  static values = {
    closeText: String,
    closeAndSendText: String,
  };

  connect() {
    this.contentTarget.required = false;
    // Some browsers (looking at you Firefox) retain the value of a textarea after a page refresh so don't assume empty state
    this.setCurrentState();
  }

  setCurrentState() {
    if (this.contentTarget.value.length === 0) {
      this.setNoContentState();
    } else {
      this.setWithContentState();
    }
  }

  setNoContentState() {
    this.closeButtonTarget.value = this.closeTextValue;
    this.sendButtonTarget.disabled = true;
  }

  setWithContentState() {
    this.closeButtonTarget.value = this.closeAndSendTextValue;
    this.sendButtonTarget.disabled = false;
  }
}
