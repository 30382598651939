import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submit"];

  static values = {
    enabled: Boolean,
  };

  connect() {
    this.enabledValue = false;
  }

  render() {
    this.submitTarget.disabled = !this.enabledValue;
    this.submitTarget.classList.toggle("disabled", !this.enabledValue);
  }

  onAddressEntered() {
    this.enabledValue = true;
  }

  enabledValueChanged() {
    this.render();
  }
}
