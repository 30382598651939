import { Controller } from "@hotwired/stimulus";

const stripNonNumeric = (string) => string.replace(/\D/g, "");

export default class extends Controller {
  static targets = ["item", "input"];

  static values = {
    current: String,
    type: String,
  };

  connect() {
    this.currentValue = this.inputTarget.value;

    this.render();
  }

  onSelect(event) {
    event.preventDefault();

    if (this.hasTypeValue && this.typeValue == "string") {
      this.currentValue = this.inputTarget.value =
        event.currentTarget.dataset.value;
    } else {
      this.currentValue = this.inputTarget.value = stripNonNumeric(
        event.currentTarget.dataset.value,
      );
    }

    this.render();
  }

  isSelected(element) {
    if (this.hasTypeValue && this.typeValue == "string") {
      return element.firstElementChild.dataset.value === this.currentValue;
    } else {
      return (
        stripNonNumeric(element.firstElementChild.dataset.value) ===
        this.currentValue
      );
    }
  }

  render() {
    this.itemTargets.forEach((element) =>
      element.classList.toggle("active", this.isSelected(element)),
    );
  }
}
