import { Controller } from "@hotwired/stimulus";
import { annotate } from "rough-notation";

export default class extends Controller {
  static values = {
    intersected: Boolean,
  };

  connect() {
    this.intersectedValue = false;

    const observer = new IntersectionObserver(this.onIntersection, {
      rootMargin: "0px 0px -100px 0px",
    });

    observer.observe(this.element);
  }

  onIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && !this.intersectedValue) {
        this.intersectedValue = true;

        const annotation = annotate(this.element, {
          type: "underline",
          color: "#99D1E3",
          duration: 2000,
          padding: -2,
          strokeWidth: 5,
        });

        annotation.show();
      }
    });
  };
}
