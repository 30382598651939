import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

// This controller allows re-saves the turbo cache before leaving a page. Useful for pages that are not reloaded when revisited,
// so they are up-to-date on back navigation.

export default class extends Controller {
  connect() {
    Turbo.cache.exemptPageFromCache();
  }
}
