import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["iframe", "loading", "introduction"];

  connect() {
    window.addEventListener("message", (event) => {
      if (event.origin && event.origin == "https://www.saltedge.com") {
        this.eventReceived(event);
      }
    });

    if (!this.hasIntroductionTarget) {
      this.iframeTarget.classList.add(
        "bank-feeds__iframe--show",
        "animated",
        "fadeIn",
      );
    }
  }

  onAcceptIntroduction(event) {
    this.introductionTarget.classList.add("hidden");
    this.iframeTarget.classList.add(
      "bank-feeds__iframe--show",
      "animated",
      "fadeIn",
    );
    this.updateProgressBarStepComplete(2);

    event.preventDefault();
  }

  eventReceived(event) {
    switch (this.stageFromEvent(event)) {
      case "cancel":
        this.onCancel();
        break;
      case "success":
        this.onSuccess(event);
        this.updateProgressBarStepComplete(3);
        break;
      default:
    }
  }

  stageFromEvent(event) {
    if (event.data == "cancel") {
      return "cancel";
    } else {
      return JSON.parse(event.data).data.stage;
    }
  }

  onSuccess(event) {
    this.iframeTarget.parentNode.removeChild(this.iframeTarget);
    this.loadingTarget.classList.add("loading__icon--show");

    const loginId = JSON.parse(event.data).data.connection_id;
    window.location.href = `/bank_feeds/salt_edge/institutions/imports/new?connection_id=${loginId}`;
  }

  onCancel() {
    this.iframeTarget.parentNode.removeChild(this.iframeTarget);
    this.loadingTarget.classList.add("loading__icon--show");

    window.location.href = "/bank_feeds";
  }

  updateProgressBarStepComplete(step) {
    if (!this.stepsContainer) {
      return;
    }

    const stepIndexToUpdate = step - 1;
    this.stepsContainer.children[stepIndexToUpdate].classList =
      "steps__step steps__step--complete";
  }

  get stepsContainer() {
    return document.getElementsByClassName("steps")[0];
  }
}
