import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "select"];

  connect() {
    this.updateVisibility();
  }

  change() {
    this.updateVisibility();
  }

  updateVisibility() {
    this.formTargets.forEach((element) => {
      const isHidden = this.isHidden(element);

      element.classList.toggle("hidden", isHidden);

      element
        .querySelectorAll("input, select")
        .forEach((input) => (input.disabled = isHidden));
    });
  }

  isHidden(element) {
    const showOnValues = JSON.parse(element.dataset.showOn);
    return !showOnValues.includes(this.selectTarget.value);
  }
}
