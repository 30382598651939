import { Controller } from "@hotwired/stimulus";
import { format } from "date-fns";
import { enter } from "el-transition";

export default class extends Controller {
  static targets = ["anchor", "rangeSelect"];

  static values = {
    days: Number,
    rangeLength: Number,
  };

  connect() {
    this.update();
  }

  update() {
    const anchor = this.anchorTarget.value;
    const days = this.daysValue;
    const rangeSelect = this.rangeSelectTarget;

    rangeSelect.options.length = 0;

    const options = [];
    for (let i = -days; i <= days; i++) {
      const startOfRange = new Date(anchor);

      startOfRange.setDate(startOfRange.getDate() + i);

      const endOfRange = new Date(startOfRange);

      endOfRange.setDate(startOfRange.getDate() + this.rangeLengthValue);

      options.push(
        new Option(
          `${format(startOfRange, "iii, d LLL y")} - ${format(
            endOfRange,
            "iii, d LLL y",
          )}`,
          startOfRange.toISOString().substr(0, 10),
          false,
          i == 0,
        ),
      );
    }

    options.forEach((option) => {
      rangeSelect.options.add(option);
    });

    enter(this.rangeSelectTarget);
  }
}
