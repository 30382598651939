import { Controller } from "@hotwired/stimulus";
import nzPost, { valueFromResponse } from "../api/nzpost";

export default class extends Controller {
  static targets = [
    "inputContainer",
    "fieldsContainer",
    "input",
    "unitNumber",
    "streetNumber",
    "streetName",
    "suburb",
    "city",
    "postcode",
    "latitude",
    "longitude",
  ];

  async connect() {
    nzPost(this.inputTarget, this.onSuccess, true);
  }

  reset(event) {
    event.preventDefault();

    this.inputTarget.value = null;
    this.updateFieldVisibilties(false);
  }

  onSuccess = (response) => {
    this.unitNumberTarget.value = valueFromResponse(response, ["UnitValue"]);
    this.streetNumberTarget.value = valueFromResponse(
      response,
      ["StreetNumber", "StreetAlpha"],
      "",
    );
    this.streetNameTarget.value = valueFromResponse(response, [
      "RoadName",
      "RoadTypeName",
      "RoadSuffixName",
    ]);
    this.suburbTarget.value = valueFromResponse(response, [
      "Suburb",
      "SuburbName",
    ]);
    this.cityTarget.value = valueFromResponse(response, ["CityTown"]);
    this.postcodeTarget.value = valueFromResponse(response, ["Postcode"]);

    if (response.NZGD2kCoord) {
      this.latitudeTarget.value = response.NZGD2kCoord.coordinates[1];
      this.longitudeTarget.value = response.NZGD2kCoord.coordinates[0];
    }

    this.updateFieldVisibilties(true);
    this.enableSubmit();
  };

  updateFieldVisibilties(showFields) {
    if (this.hasInputContainerTarget) {
      this.inputContainerTarget.classList.toggle("hidden", showFields);
    }
    if (this.hasFieldsContainerTarget) {
      this.fieldsContainerTarget.classList.toggle("hidden", !showFields);
    }
  }

  enableSubmit() {
    this.dispatch("entered");
  }
}
