import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="listing-insights"
export default class extends Controller {
  static targets = ["submitButton"];

  submit() {
    this.element.noValidate = true;
    this.submitButtonTarget.click();
    this.element.noValidate = false;
  }
}
