import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    cookieKey: String,
    cookieValue: String,
    cookieExpiry: String,
  };

  write() {
    document.cookie = `${this.cookieKeyValue}=${this.cookieValueValue}; expires=${this.cookieExpiryValue}`;
  }
}
