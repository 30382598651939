import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="photo-duplicates"
export default class extends Controller {
  static targets = ["item"];

  trigger(event) {
    if (this.hasDuplicateItems()) {
      event.preventDefault();
      event.stopImmediatePropagation();
      alert(`Please remove any duplicates to continue.`);
    }
  }

  hasDuplicateItems() {
    const checksums = this.itemTargets.map((item) => item.dataset.checksum);
    return checksums.length > new Set(checksums).size;
  }
}
