import { Controller } from "@hotwired/stimulus";

// Let a form select input show/hide certain elements on the page

// Usage:
// data-controller="toggle-select" data-toggle-active-class="hidden"
// ...
// input type="select" data-action="toggle-select#change" data-toggle-select-target="select"
// ...
// // will set "hidden" when other is selected
// div class="hidden" data-toggle-select-target="content" data-toggle-select-set-values=["other"].to_json
// // will remove "hidden" when other is selected
// div class="hidden" data-toggle-select-target="content" data-toggle-select-reset-values=["other"].to_json

export default class extends Controller {
  static targets = ["select", "content"];
  static classes = ["active"];
  static values = {
    current: String,
  };

  connect() {
    this.updateVisibility();
  }

  change() {
    this.updateVisibility();
  }

  updateVisibility() {
    this.currentValue = this.selectTarget.value;

    this.contentTargets.map((contentTarget) => {
      let setValues = contentTarget.dataset.toggleSelectSetValues;
      let resetValues = contentTarget.dataset.toggleSelectResetValues;

      if (setValues) {
        contentTarget.classList.toggle(
          this.activeClass,
          JSON.parse(setValues).includes(this.currentValue),
        );
      } else if (resetValues) {
        contentTarget.classList.toggle(
          this.activeClass,
          !JSON.parse(resetValues).includes(this.currentValue),
        );
      }
    });
  }
}
