import { Controller } from "@hotwired/stimulus";
import nzPost, { valueFromResponse } from "../api/nzpost";

export default class extends Controller {
  static targets = [
    "input",
    "internationalEntry",
    "inputContainer",
    "unitNumber",
    "streetAlpha",
    "streetNumber",
    "streetName",
    "suburb",
    "city",
    "country",
    "postcode",
    "latitude",
    "longitude",
  ];

  async connect() {
    if (this.internationalAddress) {
      this.showInternationalEntry(null);
    }

    nzPost(this.inputTarget, this.onSuccess);
  }

  onSuccess = (response) => {
    this.unitNumberTarget.value = valueFromResponse(response, ["UnitValue"]);
    this.streetNumberTarget.value = valueFromResponse(response, [
      "StreetNumber",
    ]);
    this.streetAlphaTarget.value = valueFromResponse(response, ["StreetAlpha"]);
    this.streetNameTarget.value = valueFromResponse(response, [
      "RoadName",
      "RoadTypeName",
      "RoadSuffixName",
    ]);
    this.suburbTarget.value = valueFromResponse(response, [
      "Suburb",
      "SuburbName",
    ]);
    this.cityTarget.value = valueFromResponse(response, ["CityTown"]);
    this.postcodeTarget.value = valueFromResponse(response, ["Postcode"]);

    if (response.NZGD2kCoord) {
      this.latitudeTarget.value = response.NZGD2kCoord.coordinates[1];
      this.longitudeTarget.value = response.NZGD2kCoord.coordinates[0];
    }

    this.enableSubmit();
  };

  showInternationalEntry(event) {
    event?.preventDefault();

    this.internationalEntryTarget.classList.add(
      "address__international-entry--show",
    );
    this.inputContainerTarget.classList.add("address__input--hide");

    if (!this.internationalAddress) {
      this.resetFields();
    }
    this.setEnableFieldsForInternationalEntry();
    this.enableSubmit();
  }

  hideInternationalEntry(event) {
    if (event) {
      event.preventDefault();
    }

    this.internationalEntryTarget.classList.toggle(
      "address__international-entry--show",
      false,
    );
    this.inputContainerTarget.classList.toggle("address__input--hide", false);

    if (!this.internationalAddress) {
      this.resetFields();
    }
    this.resetInternationalEntryFields();
    this.enableSubmit();
  }

  resetFields() {
    const targetsToReset = [
      "unitNumberTarget",
      "streetAlphaTarget",
      "streetNumberTarget",
      "streetNameTarget",
      "suburbTarget",
      "cityTarget",
      "postcodeTarget",
    ];

    targetsToReset.map((target) => {
      this[`${target}`].value = null;
    });
  }

  setEnableFieldsForInternationalEntry() {
    this.inputTarget.required = false;

    this.streetNumberTarget.required = true;
    this.streetNameTarget.required = true;
    this.suburbTarget.required = true;
    this.cityTarget.required = true;
    this.postcodeTarget.required = true;
  }

  resetInternationalEntryFields() {
    this.inputTarget.required = true;

    this.streetNumberTarget.required = false;
    this.streetNameTarget.required = false;
    this.suburbTarget.required = false;
    this.cityTarget.required = false;
    this.postcodeTarget.required = false;
  }

  get internationalAddress() {
    return this.hasCountryTarget && this.countryTarget.value !== "New Zealand";
  }

  enableSubmit() {
    this.dispatch("entered");
  }
}
