const trustpilotPresent = () => window.Trustpilot !== undefined;

document.addEventListener("turbo:before-cache", () => {
  if (trustpilotPresent()) {
    if (Trustpilot.Modules.WidgetManagement.widgetElements.length) {
      Trustpilot.Modules.WidgetManagement.removeWidget();
    }
  }
});

document.addEventListener("turbo:load", () => {
  if (trustpilotPresent()) {
    Trustpilot.Modules.WidgetManagement.findAndApplyWidgets();
  }
});
