import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    reload: Boolean,
  };

  connect() {
    this.element.classList.add("animated", "fadeOutRight");
    setTimeout(() => {
      this.remove();
      this.broadcastRemoval();
    }, 250);
  }

  remove() {
    this.element.parentNode.removeChild(this.element);
  }

  broadcastRemoval() {
    document.dispatchEvent(new Event("reconciled"));
  }
}
