import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";

// Connects to data-controller="date-range"
export default class extends Controller {
  static targets = ["dateInput", "fromField", "toField"];

  connect() {
    this.datePicker();
  }

  populate() {
    const [from, to] = this.datePicker().selectedDates;
    if (from) {
      this.fromFieldTarget.value = this.datePicker().formatDate(from, "d/m/Y");
    }
    if (to) {
      to.setDate(to.getDate() + 1);
      this.toFieldTarget.value = this.datePicker().formatDate(to, "d/m/Y");
    }
  }

  datePicker() {
    this.flatpickr ??= flatpickr(this.dateInputTarget, {
      dateFormat: "d/m/Y",
      mode: "range",
      allowInput: true,
      wrap: true,
    });
    return this.flatpickr;
  }
}
