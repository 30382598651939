import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import { liteClient as algoliasearch } from "algoliasearch/lite";
import {
  ALGOLIA_APP_ID,
  ALGOLIA_SEARCH_KEY,
  ALGOLIA_POST_INDEX,
} from "../util/constants";
import { autocomplete, getAlgoliaResults } from "@algolia/autocomplete-js";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);

    this.autocomplete = autocomplete({
      container: this.inputTarget,
      placeholder: "What are you looking for?",
      onSubmit(event) {
        Turbo.visit(`/resources/searches?q=${event.state.query}`);
      },
      navigator: {
        navigate({ itemUrl }) {
          Turbo.visit(itemUrl);
        },
        navigateNewTab({ itemUrl }) {
          const windowReference = window.open(itemUrl, "_blank", "noopener");

          if (windowReference) {
            windowReference.focus();
          }
        },
        navigateNewWindow({ itemUrl }) {
          window.open(itemUrl, "_blank", "noopener");
        },
      },
      getSources({ query }) {
        return [
          {
            sourceId: "Post",
            templates: {
              item({ item, createElement }) {
                return createElement(
                  "a",
                  {
                    href: item.url,
                    className: "text-gray-900 no-underline py-3 px-4",
                  },
                  [
                    createElement(
                      "div",
                      {
                        className:
                          "font-display font-bold text-lg text-gray-900",
                      },
                      item.title,
                    ),
                    createElement(
                      "div",
                      { className: "text-sm line-clamp-2 text-gray-700" },
                      item.subtitle,
                    ),
                  ],
                );
              },
            },
            getItemUrl({ item }) {
              return item.url;
            },
            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: ALGOLIA_POST_INDEX,
                    query,
                    params: {
                      hitsPerPage: 10,
                    },
                  },
                ],
              });
            },
          },
        ];
      },
    });
  }

  disconnect() {
    this.autocomplete?.destroy();
  }
}
