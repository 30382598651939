import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];

  trigger() {
    this.buttonTarget.classList.add("disabled");
    this.buttonTarget.disabled = true;
  }
}
