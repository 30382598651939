import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select/dist/js/tom-select.base.js";

// Connects to data-controller="forms--autocomplete"
export default class extends Controller {
  connect() {
    this.select = new TomSelect(this.element, {
      closeAfterSelect: true,
      allowEmptyOption: true,
      maxOptions: null,
    });
  }

  disconnect() {
    this.select?.destroy();
  }
}
