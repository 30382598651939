import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["links", "template", "fields"];

  addAssociation(event) {
    event.preventDefault();

    let content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime(),
    );

    if (this.hasFieldsTarget) {
      this.fieldsTarget.insertAdjacentHTML("beforeend", content);
    } else {
      this.linksTarget.insertAdjacentHTML("beforebegin", content);
    }
  }

  markForDeletion(event) {
    event.preventDefault();

    let wrapper = event.target.closest("*[data-nested-form-target='fields']");

    wrapper.classList.add("animated", "fadeOut");

    if (wrapper.dataset.newRecord == "true") {
      setTimeout(() => {
        wrapper.remove();
      }, 250);
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1;
      setTimeout(() => {
        wrapper.classList.add("hidden");
      }, 250);
    }
  }

  deleteAssociation(event) {
    event.preventDefault();

    let wrapper = event.target.closest("*[data-nested-form-target='fields']");

    wrapper.classList.add("animated", "fadeOut");

    setTimeout(() => {
      wrapper.remove();
    }, 250);
  }

  markForDeletionWithConfirm(event) {
    event.preventDefault();

    if (!confirm("Are you sure you want to remove this?")) {
      return;
    }

    this.markForDeletion(event);
  }
}
