import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];
  static classes = ["inactive", "active"];
  static values = ["active"];

  toggle(event) {
    this.activeValue = event.currentTarget.dataset.toggleValue;

    this.contentTargets.forEach((element) => {
      const isActive = element.dataset.toggleValue === this.activeValue;

      if (this.hasInactiveClass) {
        element.classList.toggle(this.inactiveClass, !isActive);
      }
      if (this.hasActiveClass) {
        element.classList.toggle(this.activeClass, isActive);
      }
    });
  }
}
