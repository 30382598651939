import truncate from "lodash-es/truncate";

const getElementByEvent = (event) => {
  const { id } = event.detail;
  return document.getElementById(`direct-upload-${id}`);
};

addEventListener("direct-upload:initialize", (event) => {
  const { target, detail } = event;
  const { id, file } = detail;
  const filename = truncate(file.name);
  target.parentNode.insertAdjacentHTML(
    "beforeend",
    `
    <div id="direct-upload-${id}" class="progress direct-upload__progress-container">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress-bar" role="progressbar" style="width: 0%">
        <div class="direct-upload__text">${filename}</div>
      </div>
    </div>
  `,
  );
});

addEventListener("direct-upload:start", (event) => {
  const element = getElementByEvent(event);
  element.classList.remove("direct-upload--pending");
});

addEventListener("direct-upload:progress", (event) => {
  const { id, progress } = event.detail;
  const progressElement = document.getElementById(
    `direct-upload-progress-${id}`,
  );
  progressElement.style.width = `${progress}%`;
});

addEventListener("direct-upload:error", (event) => {
  event.preventDefault();
  const { id, error } = event.detail;
  const element = document.getElementById(`direct-upload-${id}`);
  element.classList.add("direct-upload--error");
  element.setAttribute("title", error);
});

addEventListener("direct-upload:end", (event) => {
  const element = getElementByEvent(event);
  element.classList.add("direct-upload--complete");
});
