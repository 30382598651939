import { Controller } from "@hotwired/stimulus";
import debounce from "lodash-es/debounce";
import { Turbo } from "@hotwired/turbo-rails";
import Awesomplete from "awesomplete";

export default class extends Controller {
  static targets = ["input", "hiddenInput"];
  static values = { autosubmit: Boolean };

  connect() {
    this.awesomplete = new Awesomplete(this.inputTarget, {
      minChars: 0,
      filter: () => true,
      data(item) {
        if (item.suburb) {
          return `${item.suburb}, ${item.city}`;
        }

        return item.city;
      },
    });

    this.inputTarget.addEventListener("awesomplete-select", this.onSelect);
    this.inputTarget.addEventListener(
      "input",
      debounce(this.onInput, 300, {
        leading: true,
        trailing: true,
      }),
    );
  }

  disconnect() {
    this.awesomplete.destroy();

    this.inputTarget.removeEventListener("awesomplete-select", this.onSelect);
  }

  onInput = () => {
    if (!this.query) {
      return;
    }

    fetch(`/locations?q=${this.query}`)
      .then((response) => response.json())
      .then((locations) => {
        this.locations = locations;
        this.awesomplete.list = locations;
        this.awesomplete.evaluate();
      });
  };

  onSelect = (event) => {
    const location = this.locations.find((location) => {
      return (
        `${location.suburb}, ${location.city}` === event.text.value ||
        location.city === event.text.value
      );
    });

    if (this.autosubmitValue) {
      Turbo.visit(location.url);
    } else if (this.hiddenInputTarget) {
      this.hiddenInputTarget.value = location.id;
      this.emitSelectedEvent();
    }
  };

  get query() {
    return this.inputTarget.value;
  }

  emitSelectedEvent() {
    const customEvent = new CustomEvent("autocompleteSelected", {
      bubbles: true,
    });
    this.hiddenInputTarget.dispatchEvent(customEvent);
  }
}
