import { Controller } from "@hotwired/stimulus";
import * as Bowser from "bowser";

export default class extends Controller {
  static targets = ["form", "photo", "loading", "continue"];

  static values = {
    minimum: Number,
  };

  connect() {
    this.element.addEventListener(
      "direct-upload:initialize",
      this.uploadStarted.bind(this),
    );

    if (this.isSafariVersionRequiringDirectSubmit()) {
      this.formTarget.addEventListener(
        "direct-uploads:end",
        this.submit.bind(this),
      );
    }
  }

  disconnect() {
    this.element.removeEventListener(
      "direct-upload:initialize",
      this.uploadStarted.bind(this),
    );
  }

  change() {
    this.submit();
  }

  trigger(event) {
    if (this.needsMorePhotos()) {
      event.preventDefault();
      event.stopImmediatePropagation();
      alert(`You need at least ${this.minimumValue} photos to continue.`);
    }
  }

  uploadStarted() {
    this.loadingTarget.classList.add("loading__icon--show");

    if (this.hasContinueTarget) {
      this.continueTarget.classList.add("disabled");
      this.continueTarget.innerHTML = "Uploading...";
    }
  }

  submit() {
    this.formTarget.requestSubmit();
  }

  needsMorePhotos() {
    return this.hasMinimumValue && this.photoTargets.length < this.minimumValue;
  }

  isSafariVersionRequiringDirectSubmit() {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.satisfies({
      macos: {
        safari: "<16",
      },
    });
  }
}
