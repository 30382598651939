import { Turbo } from "@hotwired/turbo-rails";
import LocalTime from "local-time";
import * as ActiveStorage from "@rails/activestorage";
import "./components";
import "./controllers";
import "./bridge";
import "chartkick/chart.js";

LocalTime.start();
ActiveStorage.start();

window.Turbo = Turbo;
