import MapController, { MYRENT_BLUE } from "./map_controller";

export default class extends MapController {
  static values = {
    latitude: Number,
    longitude: Number,
    url: String,
    params: Object,
  };

  async initializeMap() {
    const querystring = this.hasParamsValue
      ? new URLSearchParams(this.paramsValue)
      : "";

    const response = await fetch(this.urlValue + "?" + querystring, {
      headers: { Accept: "application/json" },
    });

    const listings = await response.json();

    const map = new mapkit.Map(this.element, this.getOptions(listings));

    if (this.coordinate) {
      map.cameraDistance = 10000;
      map.cameraZoomRange = new mapkit.CameraZoomRange(300, 30000);
    }
  }

  getOptions(listings) {
    const annotations = listings.map((listing) => {
      const coordinate = new mapkit.Coordinate(
        parseFloat(listing.latitude, 10),
        parseFloat(listing.longitude, 10),
      );

      const annotation = new mapkit.MarkerAnnotation(coordinate);
      annotation.color = MYRENT_BLUE;
      annotation.glyphText = "🏡";
      annotation.data = { listing };
      annotation.callout = this.getCallout();

      return annotation;
    });

    if (listings.length > 0) {
      return {
        ...(this.coordinate && { center: this.coordinate }),
        annotations,
      };
    } else {
      return {
        center: this.coordinate || new mapkit.Coordinate(-37.13, 175.54),
      };
    }
  }

  getCallout() {
    return {
      calloutContentForAnnotation: function (annotation) {
        const element = document.createElement("div");
        element.className = "mk-callout-content";

        if (annotation.data.listing.image_url) {
          const imageContainerLink = element.appendChild(
            document.createElement("a"),
          );
          imageContainerLink.href = annotation.data.listing.url;
          imageContainerLink.target = "_blank";

          const imageContainer = imageContainerLink.appendChild(
            document.createElement("div"),
          );
          imageContainer.className = "mk-listing-image-container";

          const image = imageContainer.appendChild(
            document.createElement("img"),
          );
          image.className = "mk-listing-image";
          image.style = annotation.data.listing.image_url;
          image.src = annotation.data.listing.image_url;
        }

        const title = element.appendChild(document.createElement("strong"));
        title.className = "mk-title";
        title.textContent = annotation.data.listing.street_address;

        const subtitle = element.appendChild(document.createElement("div"));
        subtitle.className = "small";
        subtitle.textContent = `${annotation.data.listing.suburb}, ${annotation.data.listing.bedrooms} bed, ${annotation.data.listing.rent}/w`;

        const link = element.appendChild(document.createElement("a"));
        link.href = annotation.data.listing.url;
        link.target = "_blank";
        link.className = "small";
        link.textContent = "View";

        return element;
      },
    };
  }

  get coordinate() {
    return this.hasLatitudeValue && this.hasLongitudeValue
      ? new mapkit.Coordinate(this.latitudeValue, this.longitudeValue)
      : null;
  }
}
