import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "total", "difference", "submit"];

  static values = {
    total: Number,
  };

  connect() {
    this.submitTarget.disabled = true;
    this.updateDifference();
  }

  updateDifference() {
    let values = [this.total].concat(
      this.inputTargets.map((target) => parseFloat(target.value || 0)),
    );
    let difference = values.reduce((a, b) => a + b, 0);

    if (difference.toFixed(2) == 0.0) {
      this.differenceTarget.innerHTML = "Balanced";
      this.submitTarget.disabled = false;
    } else if (difference > 0) {
      this.differenceTarget.innerHTML = `<span class='text-red-500'>Over: $${Math.abs(
        difference,
      ).toFixed(2)}</span>`;
      this.submitTarget.disabled = true;
    } else {
      this.differenceTarget.innerHTML = `<span class='text-red-500'>Remaining: $${Math.abs(
        difference,
      ).toFixed(2)}</span>`;
      this.submitTarget.disabled = true;
    }
  }

  get total() {
    return this.totalValue * -1;
  }
}
