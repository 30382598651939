import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["block", "default"];
  static values = { selectedClasses: Array, notSelectedClasses: Array };

  connect() {
    if (this.hasDefaultTarget) {
      this.toggle(this.defaultTarget, true);
    }
  }

  change(event) {
    this.blockTargets.forEach((element) => {
      const selected = element.contains(event.target);

      this.toggle(element, selected);
    });
  }

  toggle(element, selected) {
    const selectedClasses =
      (this.hasSelectedClassesValue && this.selectedClassesValue) ||
      this.defaultSelectedClasses;
    const notSelectedClasses =
      (this.hasNotSelectedClassesValue && this.notSelectedClassesValue) ||
      this.defaultNotSelectedClasses;

    selectedClasses.forEach((klass) => {
      element.classList.toggle(klass, selected);
    });
    notSelectedClasses.forEach((klass) => {
      element.classList.toggle(klass, !selected);
    });
  }

  get defaultSelectedClasses() {
    return ["bg-blue-100", "border-blue-500", "z-10"];
  }

  get defaultNotSelectedClasses() {
    return ["border-transparent"];
  }
}
