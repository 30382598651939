import { Controller } from "@hotwired/stimulus";
import { createPopper } from "@popperjs/core";

export default class extends Controller {
  static targets = ["menu"];

  connect() {
    this.popper = createPopper(this.element, this.menuTarget, {
      placement: "bottom-end",
      strategy: "fixed",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
      ],
    });
  }

  disconnect() {
    this.popper?.destroy();
  }

  toggle(event) {
    this.menuTarget.classList.toggle("show");

    this.popper.update();
  }

  close(event) {
    if (
      this.menuTarget.classList.contains("show") &&
      !this.element.contains(event.target)
    ) {
      this.menuTarget.classList.toggle("show", false);

      this.popper.update();
    }
  }
}
