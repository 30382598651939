import { Controller } from "@hotwired/stimulus";
import dialogPolyfill from "dialog-polyfill";

export default class extends Controller {
  static targets = ["dialog"];

  connect() {
    if (typeof HTMLDialogElement === "function") {
      return;
    }

    dialogPolyfill.registerDialog(this.dialogTarget);
  }

  click(event) {
    event.preventDefault();
    this.dialogTarget.showModal();

    this.dispatch("open");
  }

  onBackdropClick(event) {
    if (this.dialogTarget.open && event.target === this.dialogTarget) {
      this.close();
    }
  }

  close() {
    this.dialogTarget.close();
  }
}
