import { Controller } from "@hotwired/stimulus";
import autosize from "autosize";

// Connects to data-controller="property-description"
export default class extends Controller {
  static targets = ["button", "title", "description"];

  async getInspired(event) {
    event.preventDefault();

    const alreadyHasContent =
      this.titleTarget.value || this.descriptionTarget.value;

    if (
      alreadyHasContent &&
      !confirm(
        "This will replace the details you have already written, would you like to continue?",
      )
    ) {
      return;
    }

    event.target.textContent = "Getting inspired...";

    const body = new FormData(event.target.form);

    // The listing edit form will have `_method` set to `PATCH`
    body.delete("_method");

    const response = await fetch(this.buttonTarget.formAction, {
      method: "POST",
      body,
    });

    if (!response.ok) {
      event.target.textContent = "Something went wrong... try again?";
      return;
    }

    try {
      const json = await response.json();

      this.titleTarget.value = json.title;

      const newline = String.fromCharCode(13, 10);
      this.descriptionTarget.value = json.description.replaceAll("\n", newline);
      autosize.update(this.descriptionTarget);

      event.target.textContent = "Try again";
    } catch {
      event.target.textContent = "Something went wrong... try again?";
    }
  }
}
