import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="forms--passport-number"
export default class extends Controller {
  static targets = ["passportNumber", "countrySelect"];
  static values = {
    patterns: Object,
  };

  connect() {
    this.setValidationPattern();
  }

  onCountrySelect() {
    this.setValidationPattern();
  }

  setValidationPattern() {
    const country = this.countrySelectTarget.value;
    const patterns = this.hasPatternsValue && this.patternsValue[country];

    if (patterns) {
      this.passportNumberTarget.pattern = patterns.pattern;
      this.passportNumberTarget.placeholder = `e.g. ${patterns.example}`;
    } else {
      this.passportNumberTarget.removeAttribute("pattern");
      this.passportNumberTarget.removeAttribute("placeholder");
    }
  }
}
