import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  static values = {
    min: String,
  };

  connect() {
    this.#setup();
    if (this.datepicker.isMobile) {
      this.datepicker.destroy();
      if (this.element.type != "date") {
        this.element.type = "date";
      }
      return;
    }
    this.#observeChanges();
  }

  #setup() {
    this.datepicker = flatpickr(this.element, this.#flatpickrOptions());
  }

  // If the input is changed by another stimulus controller, reset the datepicker by observing the input's value attribute.
  // Flatpickr makes the input hidden. Change events won't fire for hidden fields, so an observer is needed.
  #observeChanges() {
    const observer = new MutationObserver((mutations, observer) => {
      if (mutations[0].attributeName == "value") {
        this.#setup();
      }
    });
    observer.observe(this.element, {
      attributes: true,
    });
  }

  #flatpickrOptions() {
    const flatpickrOptions = {
      allowInput: true,
      altInput: true,
      altFormat: "D, j M Y",
    };

    if (this.hasMinValue) {
      flatpickrOptions.minDate = this.minValue;
    }

    return flatpickrOptions;
  }
}
