import { Controller } from "@hotwired/stimulus";

const intlSupported = window.Intl && typeof window.Intl === "object";
const metadata = intlSupported
  ? btoa(Intl.DateTimeFormat().resolvedOptions().timeZone)
  : null;

export default class extends Controller {
  connect() {
    this.element.value = metadata;
  }
}
