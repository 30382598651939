// Use case:
// Where a checkbox (resetControl) is useful to reset all other checkboxes (resetable) values to unchecked.

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["resetable", "resetControl"];

  update(event) {
    if (this.resetableTargets.includes(event.target)) {
      this.resetControlTargets.forEach((element) => {
        element.checked = false;
      });
    } else {
      this.resetableTargets.forEach((element) => {
        element.checked = false;
      });
    }
  }
}
