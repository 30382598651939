import { Controller } from "@hotwired/stimulus";
import debounce from "lodash-es/debounce";

export default class extends Controller {
  static targets = ["source", "filterable"];

  initialize() {
    this.filter = debounce(this.filter, 500).bind(this);
  }

  connect() {
    this.filterableTargets.forEach((element) => {
      element.dataset.filterText = element.innerText.toLowerCase();
    });
  }

  filter() {
    this.filterableTargets.forEach((element) => {
      element.classList.toggle(
        "hidden",
        !element.dataset.filterText.includes(
          this.sourceTarget.value.toLowerCase(),
        ),
      );
    });
  }
}
