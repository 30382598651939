import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="list-sort"
export default class extends Controller {
  static targets = ["listItem"];

  connect() {
    this.listItemTargets
      .sort((a, b) => a.textContent.localeCompare(b.textContent))
      .forEach((item) => item.parentNode.appendChild(item));
  }
}
