import { Controller } from "@hotwired/stimulus";

const SHOW_CLASS = "toggleable__content--show";

export default class extends Controller {
  static targets = ["content"];

  toggle(event) {
    this.contentTarget.classList.toggle(SHOW_CLASS);
    this.preventDefaultOnLinkClick(event);
  }

  show(event) {
    this.contentTarget.classList.add(SHOW_CLASS);
    this.preventDefaultOnLinkClick(event);
  }

  hide(event) {
    this.contentTarget.classList.remove(SHOW_CLASS);
    this.preventDefaultOnLinkClick(event);
  }

  preventDefaultOnLinkClick(event) {
    if (event.target.tagName.toLowerCase() === "a") {
      event.preventDefault();
    }
  }
}
