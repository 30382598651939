import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="scrollable"
export default class extends Controller {
  connect() {
    this.setFades();
  }

  SHOW_FADE_OFFSET = 48;

  setFades() {
    const scrollable = this.element;

    // Content not large enough to scroll
    if (scrollable.scrollHeight <= scrollable.clientHeight) {
      scrollable.classList.remove(
        "is-bottom-overflowing",
        "is-top-overflowing",
      );
      return;
    }

    scrollable.classList.toggle(
      "is-top-overflowing",
      this.distanceToTop(scrollable) > this.SHOW_FADE_OFFSET,
    );
    scrollable.classList.toggle(
      "is-bottom-overflowing",
      this.distanceToBottom(scrollable) > this.SHOW_FADE_OFFSET,
    );
  }

  distanceToBottom(el) {
    return el.scrollHeight - el.scrollTop - el.clientHeight;
  }

  distanceToTop(el) {
    return el.scrollTop;
  }
}
