import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "propertySelect",
    "categorySelect",
    "tenantSelect",
    "amount",
    "rentWarning",
  ];

  connect() {
    this.updateTenantSelection();
  }

  onPropertySelect() {
    this.updateTenantSelection();
    this.dispatchChangeEvent();
    this.updateRentWarningVisibility();
  }

  onCategorySelect() {
    this.updateTenantSelection();
    this.dispatchChangeEvent();
    this.updateRentWarningVisibility();
  }

  updateTenantSelection() {
    if (!this.hasPropertySelectTarget) {
      return;
    }
    this.updateTenantSelectionOptions();
    this.updateTenantSelectionVisibilityAndRequired();
  }

  onTenantSelect() {
    this.updateRentWarningVisibility();
  }

  dispatchChangeEvent() {
    if (this.hasAmountTarget) {
      this.dispatch("change", { detail: { rentSelected: this.rentSelected } });
    }
  }

  updateRentWarningVisibility() {
    if (this.hasRentWarningTarget) {
      this.rentWarningTarget.classList.toggle("hidden", !this.showRentWarning);
    }
  }

  updateTenantSelectionOptions() {
    const propertyTenants = this.propertyTenants;

    if (propertyTenants && this.showTenants) {
      if (!propertyTenants.tenants.length) {
        this.tenantSelectTarget.options.length = 0;
        this.tenantSelectTarget.options[0] = new Option(
          "Select a tenant",
          "",
          false,
          false,
        );
        return;
      }

      this.tenantSelectTarget.options.length = 0;
      propertyTenants.tenants.map((tenant, i) => {
        this.tenantSelectTarget.options[i] = new Option(
          tenant.name,
          tenant.id,
          false,
          tenant.id == this.currentTenantId,
        );
      });
    } else {
      this.tenantSelectTarget.options.length = 0;
      this.tenantSelectTarget.options[0] = new Option(
        "Select a tenant",
        "",
        false,
        false,
      );
    }
  }

  updateTenantSelectionVisibilityAndRequired() {
    this.propertySelectTarget.toggleAttribute("required", this.showTenants);
    this.tenantSelectTarget.toggleAttribute("required", this.showTenants);

    this.tenantSelectTarget.classList.toggle("hidden", !this.showTenants);
  }

  get showTenants() {
    return this.rentSelected || this.billPaymentSelected;
  }

  get rentSelected() {
    const selectedCategoryText =
      this.categorySelectTarget.options[this.categorySelectTarget.selectedIndex]
        .innerHTML;
    return selectedCategoryText == "Rent";
  }

  get billPaymentSelected() {
    const selectedCategoryText =
      this.categorySelectTarget.options[this.categorySelectTarget.selectedIndex]
        .innerHTML;
    return selectedCategoryText == "Tenant bill payment";
  }

  get selectedPropertyId() {
    return parseInt(this.propertySelectTarget.value);
  }

  get currentTenantId() {
    return parseInt(this.tenantSelectTarget.dataset.currentTenant);
  }

  get showRentWarning() {
    if (!this.rentSelected) {
      return false;
    }

    if (!this.tenantSelectTarget.value) {
      return false;
    }

    const allTenantLatestPaymentDates = JSON.parse(
      this.rentWarningTarget.dataset.latestTenantRentPaymentDates,
    );

    let latestTenantPaymentDateInfo = allTenantLatestPaymentDates.find(
      (tenant) => {
        return tenant.id == this.tenantSelectTarget.value;
      },
    );

    if (!latestTenantPaymentDateInfo) {
      return false;
    }

    return (
      this.rentWarningTarget.dataset.madeOn <
      latestTenantPaymentDateInfo.made_on
    );
  }

  get propertyTenants() {
    if (!this.selectedPropertyId) {
      return null;
    }

    const allTenantOptions = JSON.parse(
      this.tenantSelectTarget.dataset.tenants,
    );

    return allTenantOptions.find((property) => {
      return property.id == this.selectedPropertyId;
    });
  }
}
