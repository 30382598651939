import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["transaction"];

  evaluatePageRefresh() {
    if (this.allTransactionsReconciled) {
      this.refreshPage();
    }
  }

  get allTransactionsReconciled() {
    return this.transactionTargets.length === 0;
  }

  refreshPage() {
    Turbo.clearCache();
    Turbo.visit("");
  }
}
