import importer from "../components/importer";
import { NZ_POST_API_KEY } from "../util/constants";

export default async (element, onSuccess, includePartials = false) => {
  await importer.url("https://address.nzpost.co.nz/assets/checker.js");

  const options = {
    success: onSuccess,
    did_you_mean: true,
    search_type: "physical",
    include_partials: includePartials,
    theme: {
      boxClass: "autocomplete",
      itemClass: "autocomplete__item",
      hoverClass: "autocomplete__item--hover",
    },
  };

  return new NZPost.Addressing.Checker(element, NZ_POST_API_KEY, options);
};

export const valueFromResponse = (response, keys, joinString = " ") => {
  return (
    keys
      .map((key) => response[key])
      .filter((item) => item)
      .join(joinString) || ""
  );
};
