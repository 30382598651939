import { Controller } from "@hotwired/stimulus";
import ScrollReveal from "scrollreveal";

export default class extends Controller {
  static values = {
    delay: {
      type: Number,
      default: 500,
    },
  };

  connect() {
    ScrollReveal().reveal(this.element, {
      delay: this.delayValue,
      duration: 1000,
      distance: "10px",
    });
  }
}
