import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="offcanvas"
export default class extends Controller {
  static targets = ["backdrop", "container", "menu"];

  connect() {
    this.close();
  }

  open() {
    [this.backdropTarget, this.containerTarget, this.menuTarget].forEach(
      (element) => {
        element.classList.remove("hidden");
      },
    );
  }

  close() {
    [this.backdropTarget, this.containerTarget, this.menuTarget].forEach(
      (element) => {
        element.classList.add("hidden");
      },
    );
  }
}
