import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "content", "contentInput"];

  static values = {
    types: Array,
  };

  connect() {
    this.updateVisibility();
  }

  change() {
    this.updateVisibility();
  }

  updateVisibility() {
    const showContent = this.typesValue.includes(this.selectTarget.value);

    this.contentTarget.toggleAttribute("hidden", !showContent);

    if (showContent) {
      this.contentInputTarget?.focus();
    }
  }
}
