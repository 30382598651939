import { Controller } from "@hotwired/stimulus";
import { STRIPE_PUBLISHABLE_KEY } from "../util/constants";

export default class extends Controller {
  static targets = [
    "number",
    "expiry",
    "cvc",
    "form",
    "input",
    "submit",
    "errors",
  ];

  connect() {
    this.stripe = Stripe(STRIPE_PUBLISHABLE_KEY);

    const elements = this.stripe.elements();

    const style = {
      base: {
        fontSize: "16px",
      },
    };

    this.number = elements.create("cardNumber", { style });
    this.number.mount(this.numberTarget);
    this.number.addEventListener("change", this.onChange);

    this.expiry = elements.create("cardExpiry", { style });
    this.expiry.mount(this.expiryTarget);
    this.expiry.addEventListener("change", this.onChange);

    this.cvc = elements.create("cardCvc", { style });
    this.cvc.mount(this.cvcTarget);
    this.cvc.addEventListener("change", this.onChange);

    this.formTarget.addEventListener("submit", this.onSubmit);
  }

  disconnect() {
    this.number.removeEventListener("change", this.onChange);
    this.expiry.removeEventListener("change", this.onChange);
    this.cvc.removeEventListener("change", this.onChange);
    this.formTarget.removeEventListener("submit", this.onSubmit);
  }

  onChange = (event) => {
    this.submitTarget.disabled = false;
    this.errorsTarget.textContent = event.error ? event.error.message : "";
    this.errorsTarget.classList.toggle("fadeIn", event.error);
  };

  onSubmit = (event) => {
    event.preventDefault();

    this.submitTarget.disabled = true;

    this.stripe
      .createToken(this.number)
      .then(({ token }) => {
        this.inputTarget.setAttribute("value", token.id);
        this.formTarget.submit();
      })
      .catch(() => {
        this.submitTarget.disabled = false;
      });
  };
}
