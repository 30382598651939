import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["carousel"];
  static values = {
    offset: Number,
  };

  connect() {
    if (this.hasOffsetValue) {
      this.carouselTarget.scrollLeft = this.offsetValue;
    }
    this.setFades();

    addEventListener("resize", (event) => {
      this.setFades();
    });
  }

  setFades() {
    const visibleLeftPostion = this.carouselTarget.scrollLeft;
    const visibleRightPostion =
      this.carouselTarget.scrollLeft + this.carouselTarget.offsetWidth;

    Array.from(this.carouselTarget.children).forEach((element, index) => {
      let elementWidth = this.elementWidth(element);

      let centerPosition = elementWidth / 2 + elementWidth * index; // 24px between

      const elementVisible =
        centerPosition > visibleLeftPostion &&
        centerPosition < visibleRightPostion;

      ["opacity-25", "blur-xs", "scale-y-[.95]", "scale-y-[.98]"].forEach(
        (className) => {
          element.classList.toggle(className, !elementVisible);
        },
      );
    });
  }

  scrollLeft() {
    const firstElement = Array.from(this.carouselTarget.children)[0];

    this.carouselTarget.scrollLeft -= this.elementWidth(firstElement);
    this.setFades();
  }

  scrollRight() {
    const firstElement = Array.from(this.carouselTarget.children)[0];

    this.carouselTarget.scrollLeft += this.elementWidth(firstElement);
    this.setFades();
  }

  elementWidth(element) {
    let style = window.getComputedStyle
      ? getComputedStyle(element, null)
      : element.currentStyle;

    return (
      element.offsetWidth +
      (parseInt(style.marginLeft) || 0) +
      (parseInt(style.marginRight) || 0)
    );
  }
}
