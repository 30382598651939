import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fieldGroups"];

  change(event) {
    this.fieldGroupsTargets.forEach((element) => {
      element.disabled = element.dataset.noticeType != event.target.value;
    });
  }
}
