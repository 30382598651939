import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fieldset"];
  static values = {
    fieldset: String,
  };

  connect() {
    this.updateFieldsets();
  }

  change(event) {
    this.fieldsetValue = event.currentTarget.dataset.value;
    this.updateFieldsets();
  }

  updateFieldsets() {
    this.fieldsetTargets.forEach((element) => {
      const selected = element.dataset.value == this.fieldsetValue;
      element.disabled = !selected;
      element.classList.toggle("hidden", !selected);
    });
  }
}
