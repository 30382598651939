import { Controller } from "@hotwired/stimulus";
import debounce from "lodash-es/debounce";

export default class extends Controller {
  change() {
    debounce(this.onDebounce.bind(this), 500)();
  }

  onDebounce() {
    this.dispatch("onDebounce");
  }
}
