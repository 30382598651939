import Sortable from "sortablejs";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sortable"];

  connect() {
    Sortable.create(this.element, {
      animation: 150,
      handle: ".sortable",
      onUpdate: this.onUpdate,
      forceFallback: true,
    });
  }

  onUpdate = () => {
    this.element.requestSubmit();
  };
}
