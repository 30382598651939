import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "content"];

  static classes = ["active"];

  static values = {
    set: Boolean,
  };

  connect() {
    this.updateVisibility();
  }

  toggle() {
    this.updateVisibility();
  }

  updateVisibility() {
    this.contentTargets.map((contentTarget) => {
      let set =
        (this.setValue && this.checkboxTarget.checked) ||
        (!this.setValue && !this.checkboxTarget.checked);

      contentTarget.classList.toggle(this.activeClass, set);
    });
  }
}
