import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "submit", "content"];
  static values = { loadOnConnect: Boolean };

  connect() {
    if (this.loadOnConnectValue) {
      this.change();
    }
  }

  change() {
    if (this.notValidToSubmit()) {
      return;
    }

    // Note: The below line works on non-Safari. Have to do this hand-waving of setting and resetting action
    // and method to support all.
    // this.formTarget.requestSubmit(this.submitTarget)

    const originalAction = this.formTarget.action;
    const originalMethod = this.formTarget.method;

    this.modifyFormActionAndSubmit(
      this.submitTarget.getAttribute("formaction"),
      this.submitTarget.getAttribute("formmethod"),
    );

    this.submitTarget.click();

    this.modifyFormActionAndSubmit(originalAction, originalMethod);
  }

  notValidToSubmit() {
    return (
      !this.hasSubmitTarget ||
      this.contentTarget.value === undefined ||
      this.contentTarget.value.length < 4
    );
  }

  modifyFormActionAndSubmit(action, method) {
    this.formTarget.setAttribute("action", action);
    this.formTarget.setAttribute("method", method);
  }
}
