import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  onKeyUp(event) {
    const { target } = event;

    this.cleanInput();

    switch (event.key) {
      case "Delete":
      case "Backspace":
        if (this.fieldEmpty(target)) {
          this.focusPreviousInput(target);
        }
        break;
      case "v":
      case "Meta":
        break;
      default:
        if (this.fieldFull(target)) {
          this.focusNextInput(target);
        }
    }
  }

  onPaste(event) {
    let clipboardData = event.clipboardData
      .getData("text")
      .replace(/[\D]/g, "");

    this.inputTargets.forEach((input) => {
      input.value = clipboardData.substring(0, input.maxLength);
      clipboardData = clipboardData.substring(input.maxLength);
    });
  }

  cleanInput() {
    this.inputTargets.forEach((input) => {
      input.value = input.value.replace(/[\D]/g, "");
    });
  }

  fieldEmpty(target) {
    return target.value.length === 0;
  }

  fieldFull(target) {
    return target.value.length >= target.maxLength;
  }

  focusPreviousInput(target) {
    const index = this.inputTargets.indexOf(target);
    this.focusInput(index - 1);
  }

  focusNextInput(target) {
    const index = this.inputTargets.indexOf(target);
    this.focusInput(index + 1);
  }

  focusInput(index) {
    const input = this.inputTargets[index];

    if (input) {
      input.focus();
      input.selectionStart = input.selectionEnd = input.value.length;
    }
  }
}
