import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["price", "propertiesText"];

  static values = {
    basePrice: Number,
    pricePerProperty: Number,
  };

  change(event) {
    const propertiesCount = event.target.value;

    this.priceTarget.innerHTML = this.price(propertiesCount);
    this.propertiesTextTarget.innerHTML = propertiesCount;
  }

  price(propertiesCount) {
    return this.toMoneyFormat(
      this.basePriceValue + (propertiesCount - 1) * this.pricePerPropertyValue,
    );
  }

  toMoneyFormat(price) {
    let formatter = new Intl.NumberFormat("en-NZ", {
      style: "currency",
      currency: "NZD",
      minimumFractionDigits: 0,
    });
    return formatter.format(price);
  }
}
