import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["reasonInput", "dateInput"];

  static values = {
    lookup: Object,
  };

  setDate() {
    const suggestedDate = this.lookupValue[this.reasonInputTarget.value];
    this.dateInputTarget.value = suggestedDate;
  }
}
