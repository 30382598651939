import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source", "destination"];

  static values = {
    startIndex: Number,
    currentIndexValue: Number,
  };

  connect() {
    this.currentIndexValue = this.startIndexValue;
  }

  click(event) {
    event.preventDefault();
    this.currentIndexValue = this.currentIndexValue + 1;
    const clone = this.sourceTarget.cloneNode(true);

    clone.querySelectorAll("input").forEach((input) => {
      input.name = input.name.replace(
        this.startIndexValue,
        this.currentIndexValue,
      );
    });

    clone.classList.add("animated", "fadeIn");
    clone.classList.remove("hidden");

    this.destinationTarget.appendChild(clone);
  }
}
